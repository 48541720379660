<template>
  <!-- 全局常量页面 -->
  <div class="padding24 color_white" id="global_constants">
    <a-table
      :columns="columns"
      :data-source="tabledData"
      :pagination="false"
      :loading="tableLoading"
    >
      <span slot="number" slot-scope="number, record, index">
        <span>{{ index + 1 }}</span>
      </span>
      <span slot="operation" slot-scope="operation, record">
        <a @click="editClick(record)">编辑</a>
      </span>
    </a-table>
    <!-- 编辑弹框 -->
    <a-modal
      title="修改全局常量"
      width="60%"
      :visible="modalVisible"
      :confirmLoading="confirmLoading"
      okText="确定"
      cancelText="取消"
      @ok="ok"
      @cancel="() => (modalVisible = false)"
    >
      <a-form-model
        :model="form"
        labelAlign="left"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item label="key">
          <a-input v-model="form.key" :disabled="true"> </a-input>
        </a-form-model-item>
        <a-form-model-item label="value">
          <a-input v-model="form.value"> </a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  GetConstantResultsApi,
  UpdConstantResultApi,
} from "@/request/api/systemManage";
import { message } from "ant-design-vue";
export default {
  created() {
    this.GetConstantResultsFn();
  },
  data() {
    return {
      modalVisible: false,
      tableLoading: false,
      confirmLoading: false,
      columns: [
        {
          title: "序号",
          dataIndex: "number",
          key: "number",
          width: "5%",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "key",
          dataIndex: "key",
          key: "key",
          width: "20%",
        },
        {
          title: "value",
          dataIndex: "value",
          key: "value",
          width: "50%",
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
          width: "20%",
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          width: "5%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tabledData: [],
      form: {
        key: "",
        value: "",
      },
    };
  },
  methods: {
    ok() {
      this.confirmLoading = true;
      setTimeout(() => {
        this.confirmLoading = false;
      }, 3000);
      UpdConstantResultApi({
        key: this.form.key,
        value: this.form.value,
      }).then(({ code }) => {
        if (code == 200) {
          this.confirmLoading = false;
          this.modalVisible = false;
          message.success("编辑成功");
          this.GetConstantResultsFn();
        }
      });
    },
    editClick(record) {
      this.form = record;
      this.modalVisible = true;
    },
    GetConstantResultsFn() {
      GetConstantResultsApi().then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          console.log(data);
          this.tabledData = data;
        }
      });
    },
  },
};
</script>
 
<style lang="less" scoped>
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>